import { CustomButton } from "../components/common/customButton";

export const Page404 = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full flex-grow gap-12 text-grey600">
      <div className="text-9xl">404</div>
      <span className="text-3xl">Кажется ничего не найдено :c</span>

      <a href="/" className="mt-24">
        <CustomButton>Вернуться на главную</CustomButton>
      </a>
    </div>
  );
};
